<template>
  <div class="appstore">
    <h3 class="title">{{title}}</h3>
    <p v-html="agree"></p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agree: '',
      title: ''
    }
  },
  methods: {
    getdata() {
      var that = this
      that.$http.postApi('/getprivacy_exhibition', {}).then(res => { // 隐私政策
        if (res.data.code === 200) {
          that.agree = res.data.data.privacy
          that.title = res.data.data.privacy_title
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted(){
    this.getdata()
  }
}
</script>

<style lang="scss" scoped>
.appstore{
  margin: 0;
  width: calc(100% - 40px);
  background-color: #f7f8f9;
  height: 99vh;
  padding: 4px 20px;
  overflow: auto;
  p{
    width: 100%;
    font-size: 14px;
    text-indent: 2em;
  }
}
.title{
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>